import React, { useState } from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import FormularLayout from "./FormularLayout.js";
import { useForm } from "react-hook-form";
import axios from "axios";
import CheckNe from "../../assets/img/checkNe.svg";
import CheckJo from "../../assets/img/checkJo.svg";
import Check from "../../assets/img/pricingCheck.svg";
import Check2 from "../../assets/img/peceCheck.svg";


const Formular = styled.div`
  form {
    margin-top: 30px;

    label {
      margin-bottom: 25px;
      display: block;

      span {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }

      input {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 100%;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        padding: 15px;
        width: calc(100% - 30px);
      }

      textarea {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 100%;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        padding: 15px;
        width: calc(100% - 30px);
        resize: none;
        height: 150px;
      }
    }

    .odeslat {
      width: 100%;
      max-width: 320px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: #1D3A5B;
      border-radius: 3px;
      border: 0;
      padding: 18px 0;
      cursor: pointer;
      margin-top: 40px;
      display: block;

      &:hover {
        background: #081C33;
        cursor: pointer;
      }
    }

    .errorMsg {
      color: #F83939;
      font-family: Visuelt-Regular;
      margin-top: -15px;
      margin-bottom: 15px;
      display: block;
    }

    .checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:focus {
        .fakeCheckbox {
          border: 2px solid #243A58;
        }
      }

      input {
        display: none;

        &:focus {
          & + .fakeCheckbox {
            border: 2px solid #243A58;
          }
        }
      }

      .fakeCheckbox {
        background: #FFFFFF;
        background-image: url(${CheckNe});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      input:checked + .fakeCheckbox {
        background: #243A58;
        background-image: url(${CheckJo});
        border: 2px solid #243A58;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
      }

      p {
        width: calc(100% - 50px);
        margin: 0 !important;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }
    }
  }

  .buttonsWrap {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 50px;

    button {
      margin-bottom: 0 !important;
    }
  }

  .buttonsWrap2 {
    justify-content: left;

    div {
      margin-right: 30px;
    }
  }
`;

const Detaily = ({userData, updateData, next, previous}) => {
  let uprava = userData;

  const { register, handleSubmit, errors } = useForm(); //watch
  const onSubmit = data => handleForm(data);

  const handleForm = (r) => {
    uprava.klientInfo = {
      celeJmeno: r.celeJmeno,
      narozeni: r.narozeni,
      adresa: r.adresa,
      poznamky: r.poznamky,
    }
    
    updateData(uprava);

    axios.post('https://formspree.io/f/mrgwqkdo', uprava).then((a) => {

      let backendUrl = "";

      if (process.env.NODE_ENV === 'development') {
        backendUrl = 'http://localhost:1337'
      }

      if (process.env.NODE_ENV === 'production') {
        backendUrl = 'https://goodbye-backend.herokuapp.com'
      }

      // napojujeme se na API
      axios.post(`${backendUrl}/auth/local`, {
        identifier: 'app@goodbye.cz',
        password: 'Good.Bye123',
      }).then((res) => {
        const jwt = res.data.jwt;

        // email pro zákazníka
        axios.post(`${backendUrl}/poslimail`, {
          "to": userData.nabidka.email,
          "from": 'podpora@goodbye.cz',
          "subject": `Nezávazná poptávka předplacený pohřeb - Goodbye.cz`,
          "text": `Dobrý den, právě jsme zaregistrovali Vaší nezávaznou poptávku předplaceného pohřbu na našem webu. První krok máte za sebou. Těší nás, že zodpovědně přemýšlíte o své budoucnosti. Brzy se Vám ozve kolegyně s bližšími informacemi ohledně naší služby. V souladu s technickou normou si s každým zákazníkem musíme zavolat a dořešit detaily. Rádi se Vám časově přizpůsobíme. Hovor si můžete domluvit přímo na naší nonstop lince na čísle 315 558 136 a nebo přes odkaz jako online schůzku: https://calendly.com/janostikova/predplaceny-pohreb . Vaše objednávka je stále zcela nezávazná. Těšíme se, že Vás poznáme a blíže Vám vysvětlíme, jak vše funguje. S pozdravem, tým Goodbye.cz`,
          "html": "Dobrý den,<br/><br/>právě jsme zaregistrovali Vaší nezávaznou poptávku předplaceného pohřbu na našem webu. První krok máte za sebou. Těší nás, že zodpovědně přemýšlíte o své budoucnosti.<br/><br/>Brzy se Vám ozve kolegyně s bližšími informacemi ohledně naší služby. V souladu s technickou normou si s každým zákazníkem musíme zavolat a dořešit detaily. Rádi se Vám časově přizpůsobíme. Hovor si můžete domluvit přímo na naší nonstop lince na čísle 315 558 136 a nebo přes odkaz jako online schůzku: https://calendly.com/janostikova/predplaceny-pohreb .<br/><br/>Vaše objednávka je stále zcela nezávazná. Těšíme se, že Vás poznáme a blíže Vám vysvětlíme, jak vše funguje.<br/><br/>S pozdravem, tým Goodbye.cz",
        },
        {
          headers: {
            "Authorization": `Bearer ${jwt}`,
            "Content-Type": "application/json"
          },
        }).then(() => {
          next();
        }).catch(e => console.log(e));
      });
  
    });
  }

  const [proKohoPredplatit, setProKohoPredplatit] = useState("");

  return (
    <FormularLayout phase={3} progress={60} isSupp>
      <Formular>

        <h1>Doplňte posledních pár detailů</h1>
        <p>Tyto informace o klinetovi budou použity ve smlouvě, kterou pro vás v případě zájmu nachystáme. Vše jsme schopni pro vás zpětně upravit podle vašeho přání. Ještě jednou si vše společně následně potvrdíme.</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <label>
            <span>Celé jméno klienta (povinné)</span>
            <input ref={register({ required: true })}  type="text" name="celeJmeno" />
          </label>
          {errors.celeJmeno && <span className="errorMsg">Toto pole je povinné</span>}

          <label>
            <span>Datum narození klienta (povinné)</span>
            <input ref={register({ required: true })}  type="text" name="narozeni" />
          </label>
          {errors.narozeni && <span className="errorMsg">Toto pole je povinné</span>}

          <label>
            <span>Trvalé bydliště klienta (povinné)</span>
            <input ref={register({ required: true })}  type="text" name="adresa" />
          </label>
          {errors.adresa && <span className="errorMsg">Toto pole je povinné</span>}

          <label>
            <span>Představa o průběhu pohřbu</span>
            <textarea ref={register({ })}  name="poznamky" />
          </label>
          {errors.poznamky && <span className="errorMsg">Toto pole je povinné</span>}


          <input className="odeslat" type="submit" value={"Pokračovat"} />
        </form>



        <button onClick={() => previous()} className="zpet">← Zpět</button>
      </Formular>
    </FormularLayout>
  );
};




export default Detaily;