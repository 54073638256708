import React from "react";
import styled from "styled-components";
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import Telefon from "../../assets/img/telefon.svg";
import Hvezdy from "../../assets/img/hodnoceniKremace.svg";
import Check from "../../assets/img/check.svg";
import GoogleLogo from "../../assets/img/google.svg";
import FirmyLogo from "../../assets/img/firmyLogo.png";
import TopProgressPrepaid from "./TopProgressPrepaid";
import FeaturesV2 from "../../components/brandingV2/FeaturesV2";
import PrepaidFeature1 from "../../assets/img/prepaidFeature1.svg";
import PrepaidFeature2 from "../../assets/img/prepaidFeature2.svg";
import PrepaidFeature3 from "../../assets/img/prepaidFeature3.svg";
import FAQ from "../../components/FrequentlyAskedQ";


const faqData = [
  {
    otazka: "Co se stane, pokud za pár let přestanete fungovat?",
    odpoved: "Každá zaplacená částka za pohřeb je držená ve striktně odděleném účtu od našeho provozního fungování (pod vaším jménem) kde se zhodnocuje pro garanci zachování ceny a předcházení inflace. K vašim penězům tak nikdo nemá přístup až do bodu vašeho úmrtí, kdy se s nimi může pracovat. Nikdy se z ní do té doby nebudou platit žádné náklady ani jiné závazky. Ve velice nepravděpodobné situaci, kdy by naše společnost skončila, vám bude částka vkladu vrácena a nebo přesunuta jiné pohřební službě na vypravení pohřbu podle vašeho přání."
  },
  {
    otazka: "Jak zjistíte, že došlo k úmrtí?",
    odpoved: "V rámci naší služby obdržíte manuál a příslušenství pro usnadnění oznámení o vašem úmrtí. Zákazníkům potom doporučujeme informovat svou rodinu, přátele nebo zdravotnický personál o tom, že službu máte zakoupenou. Na cestách u sebe můžete nosit jednoduchou kartičku, podobně jako kartičku pojištěnce. Jakmile se o vašem úmrtí dozvíme, vstupuje naše společnost do role vypravitele a váš pohřeb začínáme řešit."
  },
  {
    otazka: "Jaký je postup po mém úmrtí?",
    odpoved: "Jakmile se dozvíme o vašem úmrtí, o vše se postaráme. Jako první citlivě obeznámíme vaši rodinu, přátele a vybrané známé. Připravíme parte, zajistíme převoz vašeho těla do zařízení pohřební služby a dál budeme postupovat podle vašich přání na organizaci pohřbu. Po kremaci předáme vaši urnu s popelem vybrané osobě nebo popel rozptýlíme podle vašich instrukcí. Vaší rodině budeme i nadále k dispozici, pokud by potřebovali jakkoliv pomoci. Například při dědickém řízení, komunikací s pojišťovnou, prodejem nemovitostí, nebo rušení digitálních účtů."
  },
  {
    otazka: "Co když mám zvláštní požadavky na svůj pohřeb?",
    odpoved: "Rádi vám splníme každý požadavek, který budete mít. Ozvěte se nám prosím na podpora@goodbye.cz a společně pro vás vymyslíme individuální řešení."
  },
  {
    otazka: "Co když se přestěhuji?",
    odpoved: "Naše pohřební služba má pobočky po celé České republice v rámci desítek měst. Pohřeb podle vašeho přání vám tak bez problému vypravíme kdekoliv. Pokud máte v plánu se přestěhovat do zahraničí, doporučujeme vám si zkontrolovat, jestli vaše pojistka obsahuje repatriaci (převoz těla do ČR). Pokud ne, tuto částku vám rádi přidáme do našeho balíčku jako poplatek navíc."
  },
  {
    otazka: "Co když zemřu v zahraničí?",
    odpoved: "Většina cestovních pojištění už dnes obsahuje i náklady za repatriaci (převoz těla do ČR). Pokud toto v rámci vašeho pojištění nemáte, rádi vám tuto částku přidáme do našeho balíčku jako poplatek navíc. S první nebo druhou variantou se o vás v případě vašeho úmrtí bez problému postaráme. Zařídíme převoz vašeho těla a veškerou dokumentaci s tím spojenou. Jakmile bude tělo v ČR, pokračujeme podle vámi stanovených instrukcí."
  },
  {
    otazka: "Můžu si pohřeb předplatit na splátky?",
    odpoved: "Ano. Pohřeb můžete předplatit buď celou částkou a nebo na splátky až po dobu 5 let. Pokud k úmrtí dojde před splacením celé částky, má kontaktní osoba (nejčastěji rodina nebo kamarád) na výběr ze 2 možností: A) Doplatit zbývající částku. B) Nechat si peníze vynahrazené na pohřeb vrátit a vypravit pohřeb sám."
  },
  {
    otazka: "Nemám rodinu. Kdo dá pozor, že vše proběhne dle domluvy?",
    odpoved: "Jakožto koncesovaná pohřební služba podléháme důsledným kontrolám Ministerstva pro místní rozvoj, které dohlíží na správné plnění podstaty této služby. Po vašem úmrtí musíme identicky postupovat podle instrukcí, které budou jasně zaznamenány přímo do smlouvy s vámi. Na celý proces také dohlíží vámi zvolená kontaktní osoba."
  },
  {
    otazka: "Jsem sám. Co se stane s mým popelem po pohřbu?",
    odpoved: "S urnou s popelem budeme nakládat přesně podle vašeho přání. Urnu můžeme například rozptýlit, zasadit strom, nebo uložit na místě, které je vám blízké (se souhlasem majitele nebo provozovatele pozemku). S naší službou tak máte jistotu, že se o váš pohřeb nepostará obec v rámci sociálního pohřbu a vaše ostatky tak neskončí ve společném hrobě."
  },
  {
    otazka: "Mění se cena služby podle mého věku?",
    odpoved: "Ne, nemění. Za cenu, kterou zaplatíte dnes, vám garantujeme službu, kterou dostanete kdykoliv v budoucnu. Vyhnete se tak silnému zvyšování cen,které tento obor posledních několik let postihuje."
  },
  {
    otazka: "Jdou všechny peníze, které zaplatím, na můj pohřeb?",
    odpoved: "Služba, kterou si u nás koupíte, začíná dnem podpisu smlouvy. To znamená, že musíme nejprve státu zaplatit daň z přidané hodnoty z celkové částky. Celková částka také rovnou obsahuje i náš zřizovací poplatek, ze kterého se hradí veškerá administrativa spojená se zařízením služby. Peníze ihned po zaplacení putují do striktně odděleného účtu od našeho provozního financování kde s nimi nakládáme způsobem zamezujícím k jejich znehodnocení. 100% z této částky bude potom využito na pokrytí nákladů při vypravování vašeho pohřbu."
  },
  {
    otazka: "Můžu si svůj plán změnit, pokud jsem ho už zaplatil?",
    odpoved: "Ano, ozvěte se nám a rádi vám vše pomůžeme změnit přesně podle vašich přání."
  },
  {
    otazka: "Co když budu chtít od smlouvy odstoupit?",
    odpoved: "Žádný problém. Pokud se rozhodnete odstoupit během prvních 30 dnů od podepsání, okamžitě vám vrátíme všechny peníze, které jste nám zaplatili a vše stornujeme. Pokud se rozhodnete službu zrušit například za pár let, vrátíme vám celou částku poníženou o zřizovací poplatky."
  },
  {
    otazka: "Dostanu s vámi podepsanou smlouvu, kterou si můžu uložit?",
    odpoved: "Ano. V jedné smlouvě je transparentně popsaná celá naše služba a všechny závazky naší společnosti. Smlouvu standardně podepisujeme elektronicky podle standardu eIDAS. Originál smlouvy s digitální známkou vám okamžitě pošleme do e-mailu. Pravost si potom můžete sami zkontrolovat na České poště."
  },
  {
    otazka: "Kde najdu důkladný rozpis služeb, které jsou v mém balíčku?",
    odpoved: "Celou důkladně rozepsanou službu najdete transparentně po vyplnění poptávkového formuláře na našem webu."
  },
  {
    otazka: "Musím já nebo moji blízcí zaplatit nějaké (skryté) poplatky navíc?",
    odpoved: "Ne, jakmile bude služba plně uhrazena, nic dalšího už platit nebudete. Kompletní transparentnost bereme jako jeden z hlavních pilířů fungování naší pohřební služby."
  },
  {
    otazka: "Jaké jsou rozdíly předplaceného pohřbu oproti pojištění?",
    odpoved: "Několik pojišťoven dnes nabízí pokrytí nákladů za pohřeb jako výhodu v životním pojištění. Tyto peníze se ale k rodině často dostanou až několik týdnů po pohřbu. Rodina musí také zůstat vypravitelem a stále zařizovat administrativu spojenou se zařizováním pohřbu. U nás to funguje jinak: my jsme ti, co znají vaše přání a požadavky a my jsme vypravitelem. Současně máme také okamžitě přístup k vaším finančním prostředkům. Vaší rodinu tedy tímto způsobem ušetříte všem problémům a stresu s vypravením vašeho posledního rozloučení."
  },
];

const LayoutDotaznikInner = styled.section`
  background: #FBFBFA;
  min-height: calc(100vh - 65px);
  padding-bottom: 1px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 0 auto;
    margin-top: 70px;
    padding-bottom: 1px;

    @media (max-width: 1000px) {
      display: block;
    }

    @media (max-width: 900px) {
      width: calc(100% - 50px);
      margin-top: 40px;
    }

    .left {
      width: calc(100% - 480px);
      max-width: 800px;

      @media (max-width: 1000px) {
        width: calc(100%);
        max-width: none;
      }
      
      .cta {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
        width: calc(100%);
        max-width: 290px;
        margin: 0;
        display: block;
        background: #1D3A5B;
        border-radius: 3px;
        padding: 19px 0;
        text-decoration: none;

        @media (max-width: 900px) {
          padding: 18px 0;
          max-width: 260px;
        }

        &:hover {
          background: #081C33;
          cursor: pointer;
        }
      }

      h1 {
        font-family: NewKansas;
        font-size: 32px;
        color: #000000;
        line-height: 45px;

        @media (max-width: 900px) {
          font-size: 27px;
          line-height: 39px;
        }
      }

      h2 {
        font-family: Visuelt-Medium;
        font-size: 20px;
        color: #000000;
      }

      .checklist {
        padding: 0;
        margin-top: 30px;

        li {
          list-style: none;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          img {
            margin-right: 10px;
            width: 30px;
          }
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #4D4D4D;
        line-height: 25px;
        margin-top: 35px;
        margin-bottom: 50px;

        @media (max-width: 900px) {
          margin-top: 25px;
          margin-bottom: 35px;
        }
      }

      .vyberPohrbu {
        button {
          display: inline-block;
        }
      }

      button {
        cursor: pointer;
        display: block;
        font-family: Visuelt-Medium;
        font-size: 24px;
        color: #000000;
        text-align: center;
        max-width: 320px;
        width: 100%;
        padding: 35px 0;
        background: #FFFFFF;
        border: 1px solid #dedede;
        box-shadow: 0 5px 10px 0 rgba(0,0,0,0.11);
        border-radius: 5px;
        margin-bottom: 30px;

        b {
          font-weight: unset;
          font-family: Visuelt-Bold;
          display: block;
          font-size: 26px;
        }

        span {
          display: block;
          font-size: 14px;
          margin-top: 2px;
        }

        @media (max-width: 900px) {
          margin-bottom: 25px;
        }

        &:hover {
          cursor: pointer;
          background: #f9f9f9;
        }
      }

      .active {
        background: rgb(203, 211, 222);

        &:hover {
          background: rgb(203, 211, 222);
          cursor: default;
        }
      }

      .zpet {
        background: none;
        margin: none;
        max-width: none;
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
        font-family: Visuelt-Bold;
        font-size: 16px;
        color: #4D4D4D;
        margin-top: 30px;
        display: block;
        text-decoration: none;

        width: fit-content;
        text-align: left;
        margin-bottom: 0 !important;

        @media (max-width: 900px) {
          margin-top: 30px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .siroky {
      max-width: 1150px;
      width: calc(100%);

      .vyberPohrbu {
        margin-top: 35px;
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr;
        gap: 35px;
        max-width: 1030px;

        @media (max-width: 1000px) {
          grid-template-columns: 1fr 1fr; 
          max-width: 680px;
        }

        @media (max-width: 700px) {
          grid-template-columns: 1fr;
        }

        @media (max-width: 400px) {
          gap: 25px;
        }

        button {
          margin-right: 35px;
          height: fit-content;
          cursor: pointer;
        }

        .obrazkovyButton {
          vertical-align: top;
          padding-top: 0;
          overflow: hidden;
          text-align: left;
          margin-right: 35px;
          margin-bottom: 0;
          padding-bottom: 25px;
          height: fit-content;
          position: relative;

          @media (max-width: 400px) {
            padding-bottom: 20px;
          }

          .obrazek {
            height: 200px !important;
            width: 100% !important;
          }

          h2 {
            font-family: Visuelt-Medium;
            font-size: 24px;
            color: #000000;
            display: block;
            margin: 0 auto;
            width: calc(100% - 50px);
            margin-top: 25px;

            @media (max-width: 400px) {
              margin-top: 20px;
              width: calc(100% - 40px);
            }
          }

          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #4D4D4D;
            line-height: 25px;
            display: block;
            margin: 15px auto;
            width: calc(100% - 50px);

            @media (max-width: 400px) {
              width: calc(100% - 40px);
            }
          }

          span {
            font-family: Visuelt-Bold;
            font-size: 15px;
            color: #4D4D4D;
            line-height: 25px;
            display: block;
            margin: 0 auto;
            width: calc(100% - 50px);

            @media (max-width: 400px) {
              width: calc(100% - 40px);
            }
          }

          .zahrnuto {
            color: #48B15C;
          }
        }
      }

      .vyberBlizkeho {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: 1150px;
        gap: 25px;

        @media (max-width: 1000px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 760px) {
          grid-template-columns: 1fr 1fr;
          gap: 15px;
        }

        button {
          margin-bottom: 0;

          @media (max-width: 760px) {
            width: calc((100vw - 15px - 50px)/2);
            margin: 0 auto;
          }

          @media (max-width: 500px) {
            font-size: 20px;
          }
        }
      }
    }

    .right {
      .contactCard {
        background: #FFFFFF;
        padding: 30px;
        width: 370px;

        background: #FFFFFF;
        box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
        border-radius: 5px;

        @media (max-width: 1100px) {
          width: 400px;
          padding: 25px;
        }

        @media (max-width: 1000px) {
          margin-bottom: 0;
          margin-top: 50px;
        }

        @media (max-width: 900px) {
          max-width: 400px;
          padding: 25px;
          width: calc(100% - 50px - 2px);
        }

        h2 {
          font-family: Visuelt-Medium;
          font-size: 22px;
          color: #000000;
          margin-bottom: 30px;

          @media (max-width: 900px) {
            font-size: 20px;
          }
        }

        .support {
          display: flex;
          align-items: center;

          @media (max-width: 900px) {
            display: block;
          }

          .rightSupp {
            margin-left: 25px;

            @media (max-width: 900px) {
              margin-left: 0;
              margin-top: 15px;
            }

            .phone {
              display: flex;
              align-items: center;

              img {
                height: 20px;
                margin-right: 12px;
                margin-top: -1px;
              }

              span {
                font-family: Visuelt-Medium;
                font-size: 24px;
                color: #000000;
              }
            }

            .kDispozici {
              width: fit-content;
              margin: 0 auto;
              margin-top: 10px;

              @media (max-width: 900px) {
                margin-left: 0;
              }

              .greenDot {
                background: #49B25D;
                height: 10px;
                width: 10px;
                border-radius: 100%;
                animation: pulse 2s infinite;
                display: inline-block;
                vertical-align: middle;
              }

              span {
                font-family: Visuelt-Medium;
                font-size: 16px;
                color: #48B15C;
                line-height: 24px;
                vertical-align: middle;
                margin-left: 10px;
              }

              @keyframes pulse {
                0% {
                  opacity: 1;
                }

                50% {
                  opacity: 0.25;
                }

                100% {
                  opacity: 1;
                }
              }
            }
          }
        }

        hr {
          border: 0;
          margin: 30px 0;
          border-top: 1px solid #D8D8D8;
        }

        .sites {
          .site {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .logo {
              max-width: 119px;
              max-height: 31px;
              margin-right: 15px;
            }
          }
        }

        .overenaFirma {
          display: flex;

          @media (max-width: 900px) {
            display: block;
          }

          div {  
            img {
              height: 14px;
              margin-left: 12px;
              margin-top: -2px;
            }

            span {
              font-family: Visuelt-Medium;
              font-size: 14px;
              color: #000000;
            }

            .scrollLink {
              font-family: Visuelt-Medium;
              font-size: 14px;
              color: #000000;
              text-decoration: underline;
              cursor: pointer;
              margin: 0 !important;
            }

            &:first-child {
              border-right: 2px solid #D2D2D2;
              padding-right: 15px;
              margin-right: 15px;

              @media (max-width: 900px) {
                border-right: none;
                margin-bottom: 15px;
              }
            
              img {
                height: 16px;
                margin: 0;
                margin-right: 10px;
                margin-top: -1px;

                @media (max-width: 490px) {
                  height: 12px;
                  margin-right: 7px;
                }
              }
            }
          }
        }
      }
    }
  }

  .prepulovac {
    max-width: 1150px;
    border: 0;
    border-top: 1px solid #D8D8D8;
    width: calc(100% - 100px);
    margin: 0 auto;
    border-style: solid;

    @media (max-width: 899px) {
      max-width: 700px;
      width: calc(100% - 50px);
    }
  }

  .backgroundWrap {
    /* background: #e7ecf3; */
    background: #efede9;
    padding: 1px 0;
    margin-top: 60px;
  }
`;

const FormularLayout = ({ phase, progress, children, isSupp }) => {
  return (
    <LayoutDotaznikInner>
      <TopProgressPrepaid phase={phase} progress={progress} />

    

      <section className="content">
        <div className={"left " + (!isSupp ? "siroky" : null)}>
          {children}
        </div>

        {isSupp &&
          <div className="right">
            <div className="contactCard">
              <h2>Naši specialisté vám pomohou se ve všem zorientovat</h2>

              <div className="support">
                <StaticQuery
                  query={graphql`
                    query {
                      file(relativePath: { eq: "podpora.png" }) {
                        childImageSharp {
                          fixed(width: 150, quality: 100) {
                            ...GatsbyImageSharpFixed_noBase64
                          }
                        }
                      }
                    }
                  `}
                  render={data => (
                    <Img className="obrazek" alt="Naše podpora" fixed={data.file.childImageSharp.fixed} />
                  )}
                />
                <div className="rightSupp">
                  <a href="tel:315558136" className="phone">
                    <img src={Telefon} alt="" />
                    <span>315 558 136</span>
                  </a>
                  <div className="kDispozici">
                    <div className="greenDot"></div>
                    <span>Jsme k dispozici 24/7</span>
                  </div>
                </div>
              </div>

              <hr />

              <div className="overeno">
                <div className="overenaFirma">
                  <div>
                    <img alt="" src={Check} />
                    <span>Ověřeno zákazníky</span>
                  </div>
                  <div>
                    <span>Hodnocení 4,9/5</span>
                  </div>
                </div>
                <div className="sites">
                  <div className="site">
                    <img className="logo" src={GoogleLogo} alt="Google Logo" />
                    <img className="stars" src={Hvezdy} alt="" />
                  </div>
                  <div className="site">
                    <img className="logo" src={FirmyLogo} alt="Firmy.cz Logo" />
                    <img className="stars" src={Hvezdy} alt="" />
                  </div>
                </div>
              </div>


            </div>
          </div>
        }
      </section>

      <div className="backgroundWrap">
        <FeaturesV2
          customH2="Klid v duši a jistota, že mám vše zařízeno"
          desc="Nadále si užívejte života s jisotou, že váš odchod proběhne bez zbytečného stresu navíc pro ty, které máte rádi."
          text1="Mějte jistotu, že vaše poslední přání budou respektována a uskutečněna. Bez zbytečných zmatků a nedorozumění."
          text2="Ochraňte svou rodinu, partnera nebo blízké přátele před obtížnými rozhodnutími když právě truchlí."
          text3="Vyhněte se zdražování cen. Za poslední 2 roky stouply o 42 %. Za cenu, kterou zaplatíte dnes, vám vypravíme pohřeb i za 10 let."
          heading1="Ujasněte si svá poslední přání"
          heading2="Ušetřete vaší rodině starosti navíc"
          heading3="Stejná cena i za 10 let"
          ilustrace1={PrepaidFeature1}
          ilustrace2={PrepaidFeature2}
          ilustrace3={PrepaidFeature3}
        />
      </div>

      {/* <hr className="prepulovac"/> */}
      
      
      <FAQ data={faqData} />
      
    </LayoutDotaznikInner>
  )
}



export default FormularLayout;