import React from "react";
import styled from "styled-components";


const TopProgressInner = styled.div`

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 0 auto;
    padding-top: 50px;

    @media (max-width: 900px) {
      width: calc(100% - 50px);
      padding-top: 30px;
    }
  }

  .part {
    width: calc((100% - 40px)/4);

    @media (max-width: 700px) {
      width: calc((100% - 20px)/4);
    }
    
    .outerDiv {
      height: 7px;
      border-radius: 7.5px;
      background: #ECECEC;
      overflow: hidden;
    }

    span {
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #A0A0A0;
      margin-top: 15px;
      display: block;

      @media (max-width: 700px) {
        display: none;
      }
    }
  }

  .active {
    span {
      color: #595959;
    }
  }

  .done {
    span {
      color: #56b14d;
    }
  }
  
  .mobileLabel {
    display: none;
    color: #595959;
    font-family: Visuelt-Medium;
    font-size: 16px;
    margin: 0 auto;
    margin-top: 15px;
    width: calc(100% - 50px);

    @media (max-width: 700px) {
      display: block;
    }
  }
`;

const ProgressBar = styled.div`
  height: 100%;
  width: ${props => props.number}%;
  background: #56b14d;
`;

const TopProgressPrepaid = ({ phase, progress }) => {
  return (
    <TopProgressInner>
      <div className="wrapper">
        <div className={"part " + ((phase === 1) ? " active" : ((phase > 1) && " done"))}>
          <div className={"outerDiv"}>
            <ProgressBar number={(phase === 1) ? progress : ((phase > 1) ? 100 : 0)} />
          </div>
          <span>Nabídka</span>
        </div>

        <div className={"part " + ((phase === 2) ? " active" : ((phase > 2) && " done"))}>
          <div className={"outerDiv"}>
            <ProgressBar number={(phase === 2) ? progress : ((phase > 2) ? 100 : 0)} />
          </div>
          <span>Možnosti platby</span>
        </div>

        <div className={"part " + ((phase === 3) ? " active" : ((phase > 3) && " done"))}>
          <div className={"outerDiv"}>
            <ProgressBar number={(phase === 3) ? progress : ((phase > 3) ? 100 : 0)} />
          </div>
          <span>Detaily</span>
        </div>

        <div className={"part " + ((phase === 4) ? " active" : ((phase > 4) && " done"))}>
          <div className={"outerDiv"}>
            <ProgressBar number={(phase === 4) ? progress : ((phase > 4) ? 100 : 0)} />
          </div>
          <span>Finalizace</span>
        </div>
      </div>

      {(phase === 1) &&
        <div className="mobileLabel">Nabídka</div>
      }

      {(phase === 2) &&
        <div className="mobileLabel">Možnosti platby</div>
      }

      {(phase === 3) &&
        <div className="mobileLabel">Detaily</div>
      }

      {(phase === 4) &&
        <div className="mobileLabel">Finalizace</div>
      }

    </TopProgressInner>
  );
}

export default TopProgressPrepaid;