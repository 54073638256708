import React, { useState } from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import FormularLayout from "./FormularLayout.js";
import { useForm } from "react-hook-form";
import axios from "axios";
import CheckNe from "../../assets/img/checkNe.svg";
import CheckJo from "../../assets/img/checkJo.svg";
import Check from "../../assets/img/pricingCheck.svg";
import Check2 from "../../assets/img/peceCheck.svg";
import CalendlyPanel from "../brandingV2/CalendlyPanel";
import { InlineWidget } from "react-calendly";


const Formular = styled.div`
  form {
    margin-top: 30px;

    label {
      margin-bottom: 25px;
      display: block;

      span {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }

      input {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 100%;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        padding: 15px;
        width: calc(100% - 30px);
      }

      textarea {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 100%;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        padding: 15px;
        width: calc(100% - 30px);
        resize: none;
        height: 150px;
      }
    }

    .odeslat {
      width: 100%;
      max-width: 320px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: #1D3A5B;
      border-radius: 3px;
      border: 0;
      padding: 18px 0;
      cursor: pointer;
      margin-top: 40px;
      display: block;

      &:hover {
        background: #081C33;
        cursor: pointer;
      }
    }

    .errorMsg {
      color: #F83939;
      font-family: Visuelt-Regular;
      margin-top: -15px;
      margin-bottom: 15px;
      display: block;
    }

    .checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:focus {
        .fakeCheckbox {
          border: 2px solid #243A58;
        }
      }

      input {
        display: none;

        &:focus {
          & + .fakeCheckbox {
            border: 2px solid #243A58;
          }
        }
      }

      .fakeCheckbox {
        background: #FFFFFF;
        background-image: url(${CheckNe});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      input:checked + .fakeCheckbox {
        background: #243A58;
        background-image: url(${CheckJo});
        border: 2px solid #243A58;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
      }

      p {
        width: calc(100% - 50px);
        margin: 0 !important;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }
    }
  }

  .buttonsWrap {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 50px;

    button {
      margin-bottom: 0 !important;
    }

    a {
      font-family: Visuelt-Medium;
      font-size: 18px;
      text-align: center;
      width: fit-content;
      max-width: 290px;
      margin: 0;
      display: block;
      background: none;
      border-radius: 5px;
      padding: 17px 22px;
      border: 2px solid #95A8C3;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Visuelt-Medium;
      color: #394B5C;
      font-size: 18px;

      @media (max-width: 360px) {
        padding: 15px 0;
        max-width: none;
        font-size: 17px;
        width: calc(100% - 4px);
      }

      &:hover {
        background: #E2EBF8;
        cursor: pointer;
      }
    }
  }

  .buttonsWrap2 {
    justify-content: left;

    div {
      margin-right: 30px;
    }
  }

  .calendly-inline-widget {
    width: 100% !important;
    min-height: 700px;
    margin-top: -30px;
    margin-bottom: 30px;
  }
`;

const Finish = ({userData, updateData, next, previous}) => {
  let uprava = userData;

  const { register, handleSubmit, errors } = useForm(); //watch
  const onSubmit = data => handleForm(data);

  const handleForm = (r) => {
    uprava.klientInfo = {
      celeJmeno: r.celeJmeno,
      narozeni: r.narozeni,
      adresa: r.adresa,
      poznamky: r.poznamky,
    }

    updateData(uprava);

    next();
  }

  const [proKohoPredplatit, setProKohoPredplatit] = useState("");

  return (
    <FormularLayout phase={4} progress={50} isSupp>
      <Formular>

        <h1>Poslední krok: zavolejte si s naším specialistou</h1>
        <p>V souladu s technickou normou si vždy s každým klientem ještě před sjednáním pohřbu musíme zavolat. Můžete nám zavolat přímo na telefon 315 558 136 nebo si domluvit online schůzku s využitím kalendáře níže.<br/><br/>Na schůzce s vámi ještě jednou detailněji probereme všechny detaily a vaše přání ohledně předplaceného pohřbu.<br/><br/>V mezičase si můžete v klidu projít naše obchodní podmínky a informační sdělení poskytované spotřebiteli.</p>

        <div className="buttonsWrap">
          <a href="/obchodni-podminky-predplaceny-pohreb.pdf" target="_blank">Obchodní podmínky</a>
          <a href="/informacni-sdeleni.pdf" target="_blank">Informační sdělení</a>
        </div>

        <InlineWidget url="https://calendly.com/janostikova/predplaceny-pohreb?hide_event_type_details=1&hide_gdpr_banner=1" />

        <button onClick={() => next()} className="odeslat">Pokračovat</button>

        <button onClick={() => previous()} className="zpet">← Zpět</button>
      </Formular>
    </FormularLayout>
  );
};




export default Finish;