import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import Layout from "../../../components/Layout";
import SEO from "../../../components/SEO";
import { navigate } from "gatsby"
// import DotaznikInner from "./dotaznik";
// import PrehledInner from "./prehled";
import { useCookies } from "react-cookie";
import axios from "axios";
import Zacatek from "../../../components/formularPrepaid/Zacatek";
import Nabidka from "../../../components/formularPrepaid/Nabidka";
import MoznostiPlatby from "../../../components/formularPrepaid/MoznostiPlatby";
import Detaily from "../../../components/formularPrepaid/Detaily";
import Finish from "../../../components/formularPrepaid/Finish";
import { animateScroll } from "react-scroll";
import Dekujeme from "../../../components/formularPrepaid/Dekujeme";



// const Default = () => {
//   navigate("/404/");
//   return (<></>);
// }


// const Redirect = ({url}) => {
//   if (typeof window !== 'undefined') {
//     navigate(url);
//   }
  
//   return (<></>);
// }


const Formular = () => {

  const [phase, setPhase] = useState(0);
  const [userData, setUserData] = useState({});

  function updateData(dataInput) {
    setUserData(dataInput);
    console.log(userData);
  }

  let next = () => {
    setPhase(phase + 1);
    animateScroll.scrollToTop({duration: 500});
  }

  let previous = () => {
    animateScroll.scrollToTop({duration: 500});
    setPhase(phase - 1);
  }


  useEffect(() => {
	}, []);

  return (

    <Layout
      customCtaLink={"/predplaceny-pohreb/formular/"}
      subMenuItems={[
        {
          name: "Závěti",
          url: "/zaveti/",
        },
        {
          name: "Digitální archiv",
          url: "/digitalni-archiv/",
        },
        {
          name: "Předplacený pohřeb",
          url: "/predplaceny-pohreb/",
        },
      ]}
    >

      <SEO
        title="Nezávazná nabídka na předplacený pohřeb | Goodbye.cz"
        customTitle
        description="Plánujte dopředu a ušetřete svým nejbližším starosti a stres z plánování pohřbu."
        noindex
        image={"/predplacenyOg.png"}
      />

      { phase === 0 && (
        <Zacatek
          userData={userData}
          updateData={updateData}
          next={next}
          previous={previous}
        />
      )}

      { phase === 1 && (
        <Nabidka
          userData={userData}
          updateData={updateData}
          next={next}
          previous={previous}
        />
      )}

      { phase === 2 && (
        <MoznostiPlatby
          userData={userData}
          updateData={updateData}
          next={next}
          previous={previous}
        />
      )}

      { phase === 3 && (
        <Detaily
          userData={userData}
          updateData={updateData}
          next={next}
          previous={previous}
        />
      )}

      { phase === 4 && (
        <Finish
          userData={userData}
          updateData={updateData}
          next={next}
          previous={previous}  
        />
      )}

      { phase === 5 && (
        <Dekujeme
          userData={userData}
          updateData={updateData}
          next={next}
          previous={previous}  
        />
      )}

    </Layout>
  )
}

export default Formular