import React, { useState } from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import FormularLayout from "./FormularLayout.js";
import { useForm } from "react-hook-form";
import axios from "axios";
import CheckNe from "../../assets/img/checkNe.svg";
import CheckJo from "../../assets/img/checkJo.svg";


const Formular = styled.div`
  .buttonsWrap {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 50px;

    button {
      margin-bottom: 0 !important;
    }
  }

  form {
    margin-top: 30px;
    max-width: 600px;

    label {
      margin-bottom: 25px;
      display: block;

      span {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }

      input {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 100%;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        padding: 15px;
        width: calc(100% - 30px);
      }
    }

    .odeslat {
      width: 100%;
      max-width: 320px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: #1D3A5B;
      border-radius: 3px;
      border: 0;
      padding: 18px 0;
      cursor: pointer;
      margin-top: 40px;
      display: block;

      &:hover {
        background: #081C33;
        cursor: pointer;
      }
    }

    .gdpr {
      label {
        width: 100%;
        display: block;
        white-space: nowrap;

        input {
          cursor: pointer;
          width: fit-content;
          margin-right: 10px;
        }
      }
    }

    .errorMsg {
      color: #F83939;
      font-family: Visuelt-Regular;
      margin-top: -15px;
      margin-bottom: 15px;
      display: block;
    }

    .checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:focus {
        .fakeCheckbox {
          border: 2px solid #243A58;
        }
      }

      input {
        display: none;

        &:focus {
          & + .fakeCheckbox {
            border: 2px solid #243A58;
          }
        }
      }

      .fakeCheckbox {
        background: #FFFFFF;
        background-image: url(${CheckNe});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      input:checked + .fakeCheckbox {
        background: #243A58;
        background-image: url(${CheckJo});
        border: 2px solid #243A58;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
      }

      p {
        width: calc(100% - 50px);
        margin: 0 !important;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }
    }
  }

  h2 {
    margin-top: 50px;
  }

  p {
    a {
      color: #4D4D4D;
    }
  }
`;

const Nabidka = ({userData, updateData, next, previous}) => {
  let uprava = userData;

  function zacit() {
    next();
  }

  const { register, handleSubmit, errors } = useForm(); //watch
  const onSubmit = data => handleForm(data);

  const handleForm = (r) => {
    uprava.nabidka = {
      proKohoPredplatit: proKohoPredplatit,
      jmeno: r.name,
      email: r.email,
      telefon: r.phone,
      psc: r.postCode,
    }

    updateData(uprava);

    next();
  }

  const [proKohoPredplatit, setProKohoPredplatit] = useState("");

  return (
    <FormularLayout phase={1} progress={70}>
      <Formular>

        <h1>Pro koho chcete pohřeb předplatit?</h1>
        <p>Spousta lidí pomáhá s předplacením pohřbu například svému blízkému. Pokud je tomu tak, dejte nám to prosím vědět. V případě, že plán zařizujete pro sebe zaškrtněte první variantu.</p>
        
        <div className="buttonsWrap">
          <button onClick={() => setProKohoPredplatit("Pro sebe")} className={proKohoPredplatit === "Pro sebe" ? "active" : ""}>Pro sebe</button>
          <button onClick={() => setProKohoPredplatit("Pro někoho jiného")} className={proKohoPredplatit === "Pro někoho jiného" ? "active" : ""}>Pro někoho jiného</button>
        </div>
        

        {proKohoPredplatit !== "" && <>

          {proKohoPredplatit === "Pro sebe" && <h2>Vyplňte nám prosím vaše základní informace</h2>}
          {proKohoPredplatit === "Pro někoho jiného" && <h2>Základní informace o vás a vašem blízkém</h2>}

          <form onSubmit={handleSubmit(onSubmit)}>
            <label>
              <span>Vaše celé jméno (povinné)</span>
              <input ref={register({ required: true })}  type="text" name="name" />
            </label>
            {errors.name && <span className="errorMsg">Toto pole je povinné</span>}

            <label>
              <span>Váš e-mail (povinné)</span>
              <input ref={register({ required: true })} type="email" name="email" />
            </label>
            {errors.email && <span className="errorMsg">Toto pole je povinné</span>}

            <label>
              <span>Vaše telefonní číslo (povinné)</span>
              <input ref={register({ required: true })} type="text" name="phone" />
            </label>
            {errors.phone && <span className="errorMsg">Toto pole je povinné</span>}

            <label>
              {proKohoPredplatit === "Pro sebe" && <span>PSČ (povinné)</span>}
              {proKohoPredplatit === "Pro někoho jiného" && <span>PSČ vašeho blízkého (povinné)</span>}
              <input ref={register({ required: true })} type="text" name="postCode" />
            </label>
            {errors.postCode && <span className="errorMsg">Toto pole je povinné</span>}
            
            <p className="gdpr">
              <label>
                <input type="checkbox" name="sdeleni" ref={register({ required: true })} />
                Byli jste srozuměni s <a target="_blank" href="/informacni-sdeleni.pdf">informačním sdělením pro spořitele</a>.
              </label>
            </p>
            {errors.sdeleni && <span className="errorMsg">Toto pole je povinné</span>}

            <input className="odeslat" type="submit" value={"Pokračovat"} />

            <p className="gdpr">Pokračováním souhlasíte se <a target="_blank" href="/parte/gdpr/">zpracovaním dat, GDPR</a>.</p>
          </form>
        </>}



        <button onClick={() => previous()} className="zpet">← Zpět</button>
      </Formular>
    </FormularLayout>
  );
};




export default Nabidka;