import React, { useState } from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import FormularLayout from "./FormularLayout.js";
import { useForm } from "react-hook-form";
import axios from "axios";
import CheckNe from "../../assets/img/checkNe.svg";
import CheckJo from "../../assets/img/checkJo.svg";
import Check from "../../assets/img/pricingCheck.svg";
import Check2 from "../../assets/img/peceCheck.svg";


const Formular = styled.div`
  form {
    max-width: 400px;
    margin-top: 30px;

    label {
      margin-bottom: 25px;
      display: block;

      span {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }

      input {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 100%;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        padding: 15px;
        width: calc(100% - 30px);
      }
    }

    .odeslat {
      width: 100%;
      max-width: 320px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: #1D3A5B;
      border-radius: 3px;
      border: 0;
      padding: 18px 0;
      cursor: pointer;
      margin-top: 40px;
      display: block;

      &:hover {
        background: #081C33;
        cursor: pointer;
      }
    }

    .errorMsg {
      color: #F83939;
      font-family: Visuelt-Regular;
      margin-top: -15px;
      margin-bottom: 15px;
      display: block;
    }

    .checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:focus {
        .fakeCheckbox {
          border: 2px solid #243A58;
        }
      }

      input {
        display: none;

        &:focus {
          & + .fakeCheckbox {
            border: 2px solid #243A58;
          }
        }
      }

      .fakeCheckbox {
        background: #FFFFFF;
        background-image: url(${CheckNe});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      input:checked + .fakeCheckbox {
        background: #243A58;
        background-image: url(${CheckJo});
        border: 2px solid #243A58;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
      }

      p {
        width: calc(100% - 50px);
        margin: 0 !important;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }
    }
  }

  .buttonsWrap {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 30px;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 50px;

    button {
      margin-bottom: 0 !important;

      span {
        line-height: 21px;
        margin-top: 5px !important;
        display: block;
      }
    }
  }

  .buttonsWrap2 {
    justify-content: left;

    div {
      margin-right: 30px;
    }
  }
`;

const MoznostiPlatby = ({userData, updateData, next, previous}) => {
  let uprava = userData;

  function zacit() {
    next();
  }

  const { register, handleSubmit, errors } = useForm(); //watch
  const onSubmit = data => handleForm(data);

  const handleForm = (r) => {
    uprava.platba = {
      castka: proKohoPredplatit,
      slevovyKod: r.discountCode,
    }

    updateData(uprava);

    next();
  }

  const [proKohoPredplatit, setProKohoPredplatit] = useState("");

  return (
    <FormularLayout phase={2} progress={60}>
      <Formular>

        <h1>Vyberte si jednu z našich flexiblích možností platby</h1>
        <p>Platba předplaceného pohřbu může probíhat několika způsoby. Buď můžete celou částku zaplatit jednorázově a nebo pomocí měsíčních splátek.<br/><br/>V případě předčasného úmrtí, kdy by částka nebyla celá doplacena, jí budeme vracet vámi zvolené osobě. Ta má potom na výběr pohřeb doplatit nebo ho zařídit jinde. Z toho důvodu vždy doporučujeme klientům platit v celku.</p>

        <div className="buttonsWrap">

          <button onClick={() => setProKohoPredplatit("890 Kč - 5 let")} className={proKohoPredplatit === "890 Kč - 5 let" ? "active" : ""}>
            <b>890 Kč</b>
            <span>Měsíčně po dobu 5 let<br/>(celkem 53 400 Kč)</span>
          </button>

          <button onClick={() => setProKohoPredplatit("39 900 Kč")} className={proKohoPredplatit === "39 900 Kč" ? "active" : ""}>
            <b>39 900 Kč</b>
            <span>Jednorázově</span>
          </button>

          <button onClick={() => setProKohoPredplatit("Cena na míru")} className={proKohoPredplatit === "Cena na míru" ? "active" : ""}>
            <b>Cena na míru</b>
            <span>Pro rozsáhlejší představu o pohřbu</span>
          </button>

        </div>


        <div className="buttonsWrap buttonsWrap2">
          <div>
            <h2>Základní balíček obsahuje:</h2>
            <ul className="checklist">
              <li>
                <img src={Check} alt="" />
                <span>Převoz zesnulého kdekoliv po ČR</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Uhrazení poplatků zdravotním institucím</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Vypravení důstojné kremace bez obřadu</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Základní kremační rakev, rubáš</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Informování blízkých a tisk parte</span>
              </li>
              <li>
                <img src={Check} alt="" />
                <span>Poskytnutí administrativní a emoční podpory vaší rodině Péče+</span>
              </li>
            </ul>
          </div>

          <div>
            <h2>Co jsme pro vás schopni zařídit na míru:</h2>
            <ul className="checklist">
              <li>
                <img src={Check2} alt="" />
                <span>Obřad ve vámi vybrané smuteční síni</span>
              </li>
              <li>
                <img src={Check2} alt="" />
                <span>Proslov řečníka a hudba dle vašeho výběru</span>
              </li>
              <li>
                <img src={Check2} alt="" />
                <span>Informování vašich blízkých a pozvání na pohřeb</span>
              </li>
              <li>
                <img src={Check2} alt="" />
                <span>Květinová výzdoba dle vašeho přání</span>
              </li>
              <li>
                <img src={Check2} alt="" />
                <span>Repatriace (převoz) ze zahraničí</span>
              </li>
              <li>
                <img src={Check2} alt="" />
                <span>Uložení urny na vámi vybraném hřbitově</span>
              </li>
            </ul>
          </div>
        </div>
        

        


        <h2>Máte slevový kód?</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <label>
            <span>Slevový kód</span>
            <input ref={register({ })}  type="text" name="discountCode" />
          </label>
          {errors.discountCode && <span className="errorMsg">Toto pole je povinné</span>}

          <input className="odeslat" type="submit" value={"Pokračovat"} />
        </form>



        <button onClick={() => previous()} className="zpet">← Zpět</button>
      </Formular>
    </FormularLayout>
  );
};




export default MoznostiPlatby;